<template>
  <div class="flex justify-center">
    <nav aria-label="Page navigation example">
      <ul class="inline-flex items-center -space-x-px">
        <li>
          <button class="block px-3 py-2 ml-0 leading-tight text-gray-500 border border-gray-300 rounded-l-lg" 
          @click="onClickPreviousPage" 
          :disabled="isInFirstPage"
          :class="isInFirstPage ? 'bg-gray-300': 'bg-white hover:bg-gray-100 hover:text-gray-700 '"
          >
            <span class="sr-only">Previous</span>
            <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
          </button>
        </li>
       
        <li 	v-for="page in pages"
       
        :key="page.name">
          <span
          class="z-10 px-3 py-2 leading-tight text-blue-600 border border-gray-300 bg-amber-500 text-white"
            v-if="isPageActive(page.name)"
          >{{ page.name }}
          </span>
          <a
          class="px-3 py-2 leading-tight text-black bg-white border border-gray-300"
            href="#"
            v-else
            @click.prevent="onClickPage(page.name)"
            role="button"
          >{{ page.name }}</a>
          
        </li>
        
        <li>
          <button  class="block px-3 py-2 leading-tight text-gray-500 border border-gray-300 rounded-r-lg" 
          @click="onClickNextPage"
          :disabled="isInLastPage"
          :class="isInFirstPage ? 'bg-gray-300': 'bg-white hover:bg-gray-100 hover:text-gray-700 '"
          >
            <span class="sr-only">Next</span>
            <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
          </button>
        </li>
      </ul>
    </nav>
  
</div>
</template>
<style>
.pagination {
list-style-type: none;
}

.pagination-item {
display: inline-block;
}


</style>

<script>
export default {
  props: {
      maxVisibleButtons: {
      type: Number,
      required: false,
      default: 2
      },

      totalPages: {
      type: Number,
      required: true
      },

      total: {
      type: Number,
      required: true
      },

      perPage: {
      type: Number,
      required: true
      },

      currentPage: {
      type: Number,
      required: true
      },

      hasMorePages: {
      type: Boolean,
      required: true
      }
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {
  startPage() {
    if (this.currentPage === 1) {
      return 1;
    }

    if (this.currentPage === this.totalPages) {
      return this.totalPages - this.maxVisibleButtons + 1;
    }

    return this.currentPage - 1;
  },
  endPage() {
    return Math.min(
      this.startPage + this.maxVisibleButtons,
      this.totalPages
    );
  },
  pages() {
    const range = [];

    for (let i = this.startPage; i <= this.endPage; i += 1) {
      range.push({
        name: i,
        isDisabled: i === this.currentPage
      });
    }

    return range;
  },
  isInFirstPage() {
    return this.currentPage === 1;
  },
  isInLastPage() {
    return this.currentPage === this.totalPages;
  }
},

methods: {
  onClickFirstPage() {
    this.$emit("pagechanged", 1);
  },
  onClickPreviousPage() {
    
    this.$emit("pagechanged", this.currentPage - 1);
  },
  onClickPage(page) {
    this.$emit("pagechanged", page);
  },
  onClickNextPage() {
    this.$emit("pagechanged", this.currentPage + 1);
  },
  onClickLastPage() {
    this.$emit("pagechanged", this.totalPages);
  },
  isPageActive(page) {
    return this.currentPage === page;
  }
}
};
</script>