<template>
  <div class="bg-white pt-10">
    <section class="banner_category mx-auto w-full p-10 lg:p-20">
      <div
        class="rounded-lg p-10 lg:h-[435px]"
        style="
          background: linear-gradient(0deg, #f3a323 0%, #f3a323 100%),
            linear-gradient(
              180deg,
              rgba(255, 255, 255, 0) 0%,
              rgba(255, 255, 255, 0.42) 100%
            );
        "
      >
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-6 items-center">
          <img src="../assets/images/Kiri.svg" alt="" />
          <div class="text-5xl font-semibold text-white text-center">
            About Us
          </div>
          <img src="../assets/images/Kanan.svg" alt="" />
        </div>
      </div>

      <div class="category_desc mx-auto w-full mt-5">
        <div class="container mx-auto px-4 py-8 font-primary">
          <div class="p-3 mb-2">
            <h1 class="text-2xl font-bold text-gray-900 mb-4">
              About Us
            </h1>
            <p class="text-gray-700">
              Xsportainment di Menara Multimedia adalah sarana sport dan entertainment pertama yang dimiliki oleh Telkom Property. Di dalamnya terdapat lapangan badminton, studio senam, serta fasilitas entertainment berupa deretan food court dengan menu variatif dan harga yang terjangkau. Selain itu, gym kami menyediakan peralatan home gym yang cukup lengkap, dengan ruangan yang ber-AC dan dilengkapi dengan audio speaker. Kelas olahraga yang tersedia di tempat kami adalah kelas yoga, zumba, dan pound fit, yang dipandu oleh para instruktur yang profesional dan berpengalaman.
            </p>
          </div>

          <div class="p-3 mb-2">
            <p class="text-gray-700">
              Terletak di pusat kota, Xsportainment menjadi tempat yang unik dan
              mudah dijangkau oleh siapa saja. Dengan dukungan teknologi IoT,
              pengalaman olahraga menjadi semakin interaktif. Pemesanan dan
              penggunaan fasilitas pun dapat dilakukan dengan mudah melalui
              platform online kami.
            </p>
          </div>

          <div class="p-3 mb-2">
            <p class="text-gray-700">
              Di Xsportainment, olahraga bukan hanya mengenai aktivitas fisik,
              melainkan juga momen berbagi kebahagiaan, menjalin koneksi, dan
              menciptakan kenangan berharga.
            </p>
          </div>

          <div class="p-3">
            <p class="text-gray-700 font-bold">
              Xsportainment adalah destinasi utama di pusat kota untuk para
              pecinta olahraga dan hiburan!
            </p>
          </div>
        </div>
        <!-- <div class="container mx-auto px-4 py-2 font-primary">
          <div class="p-6 mb-4">
            <h1 class="text-2xl font-bold text-gray-900 mb-4">
              Our Story
            </h1>
            <p class="text-gray-700">
              "Tidak hanya meningkatkan kebugaran fisik, rutin berolahraga juga
            mengurangi tingkat stres dan meningkatkan produktivitas di tempat
            kerja." - Penelitian oleh American College of Sports Medicine (ACSM)
            </p>
          </div>
        </div> -->
      </div>
    </section>
  </div>
</template>
